// Array of web component IDs
export default [
    "configuration-component",
    "notices-component",
    "document-templates-component",
    "transaction-batches-component",
    "disbursements-component",
    "inspection-photos-component",
    "claims-tracker-wc"
];

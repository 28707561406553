export const MOD_NAME = "Quotes & Policies";
export const LOCAL_STORAGE_MAX = 10;
export const MOD_BASEPATH = "/quotes-and-policies";
export const PAPERLESS_BILLING = "paperless-billing";
export const PAPERLESS_DOCUMENTS = "paperless-documents";
export const PAPERLESS_ENROLLMENT_LINK = "paperless-enrollment-link";
export const MYSAGESURE_REGISTER_LINK = "mysagesure-register-link";
export const POLICY_VIEW_SECTION_MARGIN = 15;
export const SAVE_PAYMENT_INFO = "save-payment-info";
export const MORATORIUMS = "moratoriums";

export const PAC_APP_ID = "ipcmanager";

export const PAYMENT_APPLICATION_CLIENT = "4000";

export const PAPERLESS_BILLING_TYPES = {
  100: { label: "Enrolled", bool: true },
  150: { label: "Enrollment Pending", bool: true },
  200: { label: "Not Enrolled", bool: false },
  true: "100",
  false: "200"
};

export const PAPERLESS_DOCUMENTS_TYPES = PAPERLESS_BILLING_TYPES;

export const DIRECT_BILL = "Direct Bill";

export const PAYMENT_PLAN_TYPES = {
  twoEasyPayCC: "EasyPay CC - 2 Payments",
  fourEasyPayCC: "EasyPay CC - 4 Payments",
  tenEasyPayCC: "EasyPay CC - 10 Payments",
  fullEasyPayCC: "EasyPay CC - 1 Payment",
  twoEasyPayACH: "EasyPay ACH - 2 Payments",
  fourEasyPayACH: "EasyPay ACH - 4 Payments",
  tenEasyPayACH: "EasyPay ACH - 10 Payments",
  fullEasyPayACH: "EasyPay ACH - 1 Payment",
  fourPay: `${DIRECT_BILL} - 4 Payments`,
  tenPay: "Recurring ACH - 10 Payments",
  tenPayInvoice: `${DIRECT_BILL} - 10 Payments`,
  twoPay: `${DIRECT_BILL} - 2 Payments`,
  TwoPay: `${DIRECT_BILL} - 2 Payments`, // capitalization is inconsistent here
  fullPay: `${DIRECT_BILL} - 1 Payment`,
  invoice: "Mortgagee Escrow",
  monthly: "Monthly"
};

// once using @apollo/client, switch to use their network status enum
// https://www.apollographql.com/docs/react/data/queries/#inspecting-loading-states
export const NETWORK_STATUS = {
  loading: 1,
  setVariables: 2,
  fetchMore: 3,
  refetch: 4,
  poll: 6,
  ready: 7,
  error: 8
};

export const ORIGINATING_SYSTEMS = {
  pxClient: "Agent Portal",
  pxServer: "Agent Portal"
};

export const POLICY_STATES = {
  ACTIVEPOLICY: {
    state: "ACTIVEPOLICY",
    display: "Active Policy",
    class: "success"
  },
  PENDINGCANCELLATION: {
    state: "PENDINGCANCELLATION",
    display: "Pending Cancellation",
    class: "warning"
  },
  CANCELLEDPOLICY: {
    state: "CANCELLEDPOLICY",
    display: "Cancelled Policy",
    class: "danger"
  },
  TRANSFERREDPOLICY: {
    state: "TRANSFERREDPOLICY",
    display: "Transferred Policy",
    class: "danger"
  },
  NONRENEWEDPOLICY: {
    state: "NONRENEWEDPOLICY",
    display: "Non-Renewed Policy",
    class: "warning"
  },
  PENDINGNONRENEWAL: {
    state: "PENDINGNONRENEWAL",
    display: "Pending Non-Renewal",
    class: "warning"
  },
  ACTIVEQUOTE: {
    state: "ACTIVEQUOTE",
    display: "Active Quote",
    class: "success"
  },
  INCOMPLETEQUOTE: {
    state: "INCOMPLETEQUOTE",
    display: "Incomplete Quote",
    class: "danger"
  }
};

export const COMMERCIAL_LINES = ["BOP"];

export const IAT_CARRIER_GROUP_IDS = ["IAT", "SIC", "FNHC"];

export const UW_CONDITION_TEXT = "UnderwritingCondition";

export const DOCUMENT_GROUP_MAPPING = {
  Enrollment: [
    "authorization$",
    "application$",
    "quotesheet$",
    "proof$",
    "_letter$",
    "_payment$"
  ],
  Policy_Packages: ["newbusiness", "policyrenew", "declaration", "declination"],
  Invoicing: ["invoice", "receipt", "payment"],
  Endorsements: ["endorse"],
  Cancellations: ["reinstate", "nonrenew", "cancel", "uwpcn", "pcn"]
};

// reference constants below

export const ODEN_REFERENCE_ID = "ODEN_STATUS";

export const ODEN_STATUSES = {
  ODEN_PENDING_CANCELLATION: "ODEN_PENDING_CANCELLATION",
  ODEN_RESCIND_PENDING_CANCELLATION: "ODEN_RESCIND_PENDING_CANCELLATION"
};

export const ODEN_STATUS_MAPPING = {
  [ODEN_STATUSES.ODEN_PENDING_CANCELLATION]: {
    state: ODEN_STATUSES.ODEN_PENDING_CANCELLATION,
    display: "ODEN Pending Cancellation",
    class: "warning",
    btnLabel: "Add ODEN pending cancel",
    inverseStatus: ODEN_STATUSES.ODEN_RESCIND_PENDING_CANCELLATION
  },
  [ODEN_STATUSES.ODEN_RESCIND_PENDING_CANCELLATION]: {
    state: ODEN_STATUSES.ODEN_RESCIND_PENDING_CANCELLATION,
    display: "ODEN Rescind Pending Cancellation",
    class: "warning",
    btnLabel: "Rescind ODEN pending cancel",
    inverseStatus: ODEN_STATUSES.ODEN_PENDING_CANCELLATION
  }
};

export const NON_RENEW_INDICATOR_STATUSES = {
  DEFAULT: "DoNotRenew",
  RESCIND: "DoNotRenewRescission"
};

export const DO_NOT_REINSTATE_FLAG_STATUSES = {
  DEFAULT: "DoNotReinstate",
  RESCIND: "DoNotReinstateRescission"
};

export const HOLD_RENEWAL_FLAG_STATUSES = {
  DEFAULT: "HoldRenewal",
  RESCIND: "HoldRenewalRescission"
};

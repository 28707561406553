import { isEmpty, difference } from "lodash";
import moment from "moment";
import { ODEN_STATUSES, POLICY_STATES } from "qandp/constants";
import { getOdenReference } from "qandp/utils";
import {
  CANCEL_ACTIONS,
  DERIVED_EFFECTIVE_DATE_CODES,
  ALLOWED_REASONS_UWC,
  REASON_CODES,
  REASON_CODE_OPTIONS,
  STATE_SPECIFIC_EXCLUSIONS,
  REASON_CODE_ENUM_OVERRIDES
} from "../../constants/cancel-constants";
import REASON_CODE_ENUM from "../../constants/reason-codes";

export const calculatePolicyState = ({ policyState, pendingCancellation }) =>
  policyState === POLICY_STATES.ACTIVEPOLICY.state &&
  !isEmpty(pendingCancellation)
    ? POLICY_STATES.PENDINGCANCELLATION.state
    : policyState;

export const effectiveDateDefaults = {
  [CANCEL_ACTIONS.Cancellation]: ({
    state,
    policy,
    userValues: { reasonCode }
  }) =>
    state === POLICY_STATES.PENDINGCANCELLATION.state &&
    reasonCode === policy.pendingCancellation.reasonCode.value
      ? moment(policy.pendingCancellation.effectiveDate.substr(0, 10)).toDate()
      : null,
  [CANCEL_ACTIONS.PendingCancellation]: ({
    userValues: { reasonCode },
    policy
  }) => {
    if (reasonCode === REASON_CODE_ENUM.NONPAYMENT_OF_PREMIUM_FLAT) {
      return moment(policy.currentTerm.effectiveDate.substr(0, 10)).toDate();
    }
    return DERIVED_EFFECTIVE_DATE_CODES.includes(reasonCode) ? "" : null;
  },
  [CANCEL_ACTIONS.Reinstatement]: () => moment().toDate(),
  [CANCEL_ACTIONS.PendingCancellationRescission]: ({ policy }) => {
    if (policy.pendingCancellation.effectiveDate) {
      moment(policy.pendingCancellation.effectiveDate.substr(0, 10)).toDate();
    }
  }
};

export const effectiveDateDisabledActions = {
  [CANCEL_ACTIONS.Cancellation]: ({ state, reasonCode, policy }) =>
    state === POLICY_STATES.PENDINGCANCELLATION.state &&
    reasonCode === policy.pendingCancellation.reasonCode.value,
  [CANCEL_ACTIONS.PendingCancellation]: ({
    reasonCode,
    pendingCancellationEffectiveDateDisableAll
  }) =>
    pendingCancellationEffectiveDateDisableAll ||
    DERIVED_EFFECTIVE_DATE_CODES.includes(reasonCode),
  [CANCEL_ACTIONS.Reinstatement]: () => true,
  [CANCEL_ACTIONS.PendingCancellationRescission]: () => true
};

export const hasSameEffectiveDate = ({
  formValues: { effectiveDate },
  policy: {
    currentTerm: { effectiveDate: policyEffectiveDate }
  }
}) =>
  moment(policyEffectiveDate).isSame(
    moment(effectiveDate || moment()).format("YYYY-MM-DD")
  );

export const setReasonCode = ({ action, hasFlag, formValues, policy }) =>
  action === CANCEL_ACTIONS.Cancellation &&
  hasFlag &&
  hasSameEffectiveDate({ formValues, policy }) &&
  formValues.reasonCode === "15"
    ? "1"
    : formValues.reasonCode;

export const hideInsuredCancelReason = ({ formValues }) =>
  !(
    (formValues.action === CANCEL_ACTIONS.Cancellation ||
      formValues.action === CANCEL_ACTIONS.BopCancellation) &&
    (formValues.reasonCode === REASON_CODE_ENUM.INSURED_REQUEST ||
      formValues.reasonCode ===
        REASON_CODE_ENUM_OVERRIDES[REASON_CODE_ENUM.INSURED_REQUEST])
  );

export const hideSameDayDelivery = ({ formValues }) =>
  formValues.action !== "PendingCancellation";

export const disableSameDayDelivery = () => {
  const startTime = "00:00";
  const endTime = "11:30";

  let todayNY = new Date().toLocaleString("en-US", {
    timeZone: "America/New_York"
  });

  todayNY = new Date(todayNY);

  const s = startTime.split(":");
  const start = new Date(
    todayNY.getFullYear(),
    todayNY.getMonth(),
    todayNY.getDate(),
    parseInt(s[0], 10),
    parseInt(s[1], 10)
  );

  const e = endTime.split(":");
  const end = new Date(
    todayNY.getFullYear(),
    todayNY.getMonth(),
    todayNY.getDate(),
    parseInt(e[0], 10),
    parseInt(e[1], 10)
  );

  const isWeekday = todayNY.getDay() % 6 !== 0;

  return todayNY < start || todayNY > end || !isWeekday;
};

export const hideUnderWritingConditions = ({ display, formValues }) =>
  !display ||
  !formValues.action.includes(CANCEL_ACTIONS.PendingCancellation) ||
  !ALLOWED_REASONS_UWC.includes(formValues.reasonCode);

export const determineReasonLabel = (type, codes, value) => {
  const isBop = type === "BOP";

  if (isBop && codes[`${value}_bop`]) return codes[`${value}_bop`];
  return codes[value];
};

export const isMoreThan60DaysPastCancel = eventHistory => {
  const cancelledEvents = eventHistory?.filter(
    event => event?.type === "Cancel"
  );
  const mostRecentCancelEvent = cancelledEvents[cancelledEvents.length - 1];

  return (
    moment().diff(moment(mostRecentCancelEvent?.effectiveDate), "days") > 60
  );
};

export const isFLSicPolicy = ({ carrierId, policyData }) =>
  carrierId === "SIC" && policyData?.PropertyState === "FL";

export const isGeicoPolicy = terms => {
  const [firstTerm] = terms;

  return firstTerm?.dataItems?.find(
    item => item.name === "OpAgencyName" && item.value.includes("Geico")
  );
};

export const buildReasonCodeOptions = ({
  state,
  action,
  policy,
  policy: {
    pendingCancellation,
    policyData: { PropertyState, PolicyType }
  },
  bookRollReasonCode,
  renewalNotAcceptedReasonCode,
  insufficientRateExposureReasonCode,
  insuredRequestReasonCodeOverride,
  duplicatePolicyReasonCode,
  showReasonCode701
}) => {
  let options = [{ value: "", label: "Select Reason Code" }];
  const odenReference = getOdenReference(policy) || {};
  const odenPendingCancel =
    odenReference.state === ODEN_STATUSES.ODEN_PENDING_CANCELLATION;
  const pendingCancel = state === POLICY_STATES.PENDINGCANCELLATION.state;
  const isBop = PolicyType === "BOP";

  if (isBop && !pendingCancel && !odenPendingCancel) {
    let cleanedArray = [];
    cleanedArray = difference(
      REASON_CODE_OPTIONS[action],
      STATE_SPECIFIC_EXCLUSIONS[PropertyState]
    );
    if (!renewalNotAcceptedReasonCode) {
      cleanedArray = cleanedArray.filter(
        option => option !== REASON_CODE_ENUM.RENEWAL_NOT_ACCEPTED
      );
    }

    return [
      { value: "", label: "Select Reason Code" },
      ...(cleanedArray || []).map(value => ({
        label: REASON_CODES[value],
        value
      }))
    ];
  }

  if (
    (action === CANCEL_ACTIONS.Cancellation ||
      action === CANCEL_ACTIONS.BopCancellation) &&
    odenPendingCancel &&
    pendingCancel
  ) {
    options = [
      {
        value: odenReference.reasonCode,
        label: determineReasonLabel(
          PolicyType,
          REASON_CODES,
          odenReference.reasonCode
        )
      },
      {
        value: pendingCancellation.reasonCode.value,
        label: determineReasonLabel(
          PolicyType,
          REASON_CODES,
          pendingCancellation.reasonCode.value
        )
      },
      {
        value: REASON_CODE_ENUM.INSURED_REQUEST,
        label: REASON_CODES[REASON_CODE_ENUM.INSURED_REQUEST]
      },
      {
        value: REASON_CODE_ENUM.CANCEL_REWRITE,
        label: REASON_CODES[REASON_CODE_ENUM.CANCEL_REWRITE]
      }
    ];

    if (bookRollReasonCode) {
      options = [
        ...options,
        {
          label: REASON_CODES[REASON_CODE_ENUM.BOOK_ROLL],
          value: REASON_CODE_ENUM.BOOK_ROLL
        }
      ];
    }

    return options;
  }

  if (
    (action === CANCEL_ACTIONS.Cancellation ||
      action === CANCEL_ACTIONS.BopCancellation) &&
    odenPendingCancel
  ) {
    options = [
      {
        value: odenReference.reasonCode,
        label: determineReasonLabel(
          PolicyType,
          REASON_CODES,
          odenReference.reasonCode
        )
      },
      {
        value: REASON_CODE_ENUM.INSURED_REQUEST,
        label: REASON_CODES[REASON_CODE_ENUM.INSURED_REQUEST]
      },
      {
        value: REASON_CODE_ENUM.CANCEL_REWRITE,
        label: REASON_CODES[REASON_CODE_ENUM.CANCEL_REWRITE]
      }
    ];

    if (bookRollReasonCode) {
      options = [
        ...options,
        {
          label: REASON_CODES[REASON_CODE_ENUM.BOOK_ROLL],
          value: REASON_CODE_ENUM.BOOK_ROLL
        }
      ];
    }

    return options;
  }

  if (
    (action === CANCEL_ACTIONS.Cancellation ||
      action === CANCEL_ACTIONS.BopCancellation) &&
    pendingCancel
  ) {
    options = [
      {
        value: pendingCancellation.reasonCode.value,
        label: determineReasonLabel(
          PolicyType,
          REASON_CODES,
          pendingCancellation.reasonCode.value
        )
      },
      {
        value: REASON_CODE_ENUM.INSURED_REQUEST,
        label: REASON_CODES[REASON_CODE_ENUM.INSURED_REQUEST]
      },
      {
        value: REASON_CODE_ENUM.CANCEL_REWRITE,
        label: REASON_CODES[REASON_CODE_ENUM.CANCEL_REWRITE]
      }
    ];

    if (bookRollReasonCode) {
      options = [
        ...options,
        {
          label: REASON_CODES[REASON_CODE_ENUM.BOOK_ROLL],
          value: REASON_CODE_ENUM.BOOK_ROLL
        }
      ];
    }

    return options;
  }

  if (
    action === CANCEL_ACTIONS.Cancellation &&
    !odenPendingCancel &&
    !pendingCancel
  ) {
    options = [
      ...options,
      {
        label: REASON_CODES[REASON_CODE_ENUM.DISCOVERY_OF_FRAUD],
        value: REASON_CODE_ENUM.DISCOVERY_OF_FRAUD
      },
      {
        label: REASON_CODES[REASON_CODE_ENUM.NONPAYMENT_OF_PREMIUM_FLAT],
        value: REASON_CODE_ENUM.NONPAYMENT_OF_PREMIUM_FLAT
      },
      {
        label: REASON_CODES[REASON_CODE_ENUM.REPLACEMENT_POLICY],
        value: REASON_CODE_ENUM.REPLACEMENT_POLICY
      }
    ];

    if (renewalNotAcceptedReasonCode) {
      options = [
        ...options,
        {
          label: REASON_CODES[REASON_CODE_ENUM.RENEWAL_NOT_ACCEPTED],
          value: REASON_CODE_ENUM.RENEWAL_NOT_ACCEPTED
        }
      ];
    }

    options = [
      ...options,
      {
        label: REASON_CODES[REASON_CODE_ENUM.INSURED_REQUEST],
        value: insuredRequestReasonCodeOverride
          ? REASON_CODE_ENUM_OVERRIDES[REASON_CODE_ENUM.INSURED_REQUEST]
          : REASON_CODE_ENUM.INSURED_REQUEST
      },
      {
        label: REASON_CODES[REASON_CODE_ENUM.CANCEL_REWRITE],
        value: REASON_CODE_ENUM.CANCEL_REWRITE
      }
    ];

    if (bookRollReasonCode) {
      options = [
        ...options,
        {
          label: REASON_CODES[REASON_CODE_ENUM.BOOK_ROLL],
          value: REASON_CODE_ENUM.BOOK_ROLL
        }
      ];
    }

    if (duplicatePolicyReasonCode) {
      options = [
        ...options,
        {
          label: REASON_CODES[REASON_CODE_ENUM.DUPLICATE_POLICY],
          value: REASON_CODE_ENUM.DUPLICATE_POLICY
        }
      ];
    }

    return options;
  }

  options = [
    { value: "", label: "Select Reason Code" },
    ...(REASON_CODE_OPTIONS[action] || []).map(value => ({
      label: REASON_CODES[value],
      value
    }))
  ];

  if (action === CANCEL_ACTIONS.Reinstatement) {
    if (bookRollReasonCode) {
      options = [
        ...options,
        {
          label: REASON_CODES[REASON_CODE_ENUM.BOOK_ROLL],
          value: REASON_CODE_ENUM.BOOK_ROLL
        }
      ];
    }
  }

  if (action === CANCEL_ACTIONS.PendingCancellation) {
    if (insufficientRateExposureReasonCode) {
      options = [
        ...options,
        {
          label: REASON_CODES[REASON_CODE_ENUM.INSUFFICIENT_RATE_EXPOSURE],
          value: REASON_CODE_ENUM.INSUFFICIENT_RATE_EXPOSURE
        }
      ];
    }
  }

  if (action === CANCEL_ACTIONS.PendingCancellationRescission) {
    options = options.filter(o => o.value !== REASON_CODE_ENUM.INSURED_REQUEST);
  }

  options = showReasonCode701
    ? options
    : options.filter(
        item =>
          item.value !==
          REASON_CODE_ENUM.INSPECTION_CONDITION_EXCEEDS_NORMALLY_ACCEPTED_UW_THRESHOLD
      );

  return options;
};

export const UserJob = Object.freeze({
  CSR: "csr",
  CSR_SUPERVISOR: "csr supervisor",
  UNDERWRITER: "underwriter",
  UNDERWRITER_SUPERVISOR: "underwriter supervisor",
  UNDERWRITING_SUPPORT: "underwriting support",
  UNDERWRITING_SUPPORT_SUPERVISOR: "underwriting support supervisor",
  OTHER: "other",
  UNKNOWN: "unknown"
});

/**
 * Determines the department for a CSR or Underwriter within Mixpanel.
 * A CSR or Underwriter operates in either the Personal or Commercial area,
 * which are tracked as departments in Mixpanel.
 *
 * Note: Supervisors and underwriter support staff may not belong to a specific
 * department. In such cases, they are assigned to "OTHER".
 */
export const UserJobDepartment = Object.freeze({
  PERSONAL: "personal",
  COMMERCIAL: "commercial",
  PERSONAL_AND_COMMERCIAL: "personal and commercial",
  OTHER: "other",
  UNKNOWN: "unknown"
});

/**
 * These are the roles returned from ixRoles.
 */
export const iXRoles = Object.freeze({
  CSR_PERSONAL: "csr_personal",
  CSR_COMMERCIAL: "csr_commercial",
  CSR_SUPERVISOR: "csr_supervisor",
  UNDERWRITER_PERSONAL: "underwriter_personal",
  UNDERWRITER_COMMERCIAL: "underwriter_commercial",
  UNDERWRITING_SUPERVISOR: "underwriting_supervisor",
  UNDERWRITING_SUPPORT_STAFF: "underwriting_support_staff",
  UNDERWRITING_SUPPORT_SUPERVISOR: "underwriting_support_supervisor"
});

function isCSRStaff(roles) {
  return (
    roles.includes(iXRoles.CSR_PERSONAL) ||
    roles.includes(iXRoles.CSR_COMMERCIAL)
  );
}

function isCSRSupervisor(roles) {
  return roles.includes(iXRoles.CSR_SUPERVISOR);
}

function isUnderwriterStaff(roles) {
  return (
    roles.includes(iXRoles.UNDERWRITER_PERSONAL) ||
    roles.includes(iXRoles.UNDERWRITER_COMMERCIAL)
  );
}

function isUnderwriterSupervisor(roles) {
  return roles.includes(iXRoles.UNDERWRITING_SUPERVISOR);
}

function isUnderwriterSupportStaff(roles) {
  return roles.includes(iXRoles.UNDERWRITING_SUPPORT_STAFF);
}

function isUnderwriterSupportSupervisor(roles) {
  return roles.includes(iXRoles.UNDERWRITING_SUPPORT_SUPERVISOR);
}

function isUserInUnspecifiedDepartments(roles) {
  return (
    isCSRSupervisor(roles) ||
    isUnderwriterSupervisor(roles) ||
    isUnderwriterSupportStaff(roles) ||
    isUnderwriterSupportSupervisor(roles)
  );
}

function isCSRInPersonalAndCommercialDepartment(roles) {
  return (
    roles.includes(iXRoles.CSR_PERSONAL) &&
    roles.includes(iXRoles.CSR_COMMERCIAL)
  );
}

function getCSRStaffDepartment(roles) {
  let csrDepartment = UserJobDepartment.UNKNOWN;
  if (isCSRStaff(roles)) {
    if (isCSRInPersonalAndCommercialDepartment(roles)) {
      csrDepartment = UserJobDepartment.PERSONAL_AND_COMMERCIAL;
    } else if (roles.includes(iXRoles.CSR_PERSONAL)) {
      csrDepartment = UserJobDepartment.PERSONAL;
    } else if (roles.includes(iXRoles.CSR_COMMERCIAL)) {
      csrDepartment = UserJobDepartment.COMMERCIAL;
    } else {
      csrDepartment = UserJobDepartment.OTHER;
    }
  }
  return csrDepartment;
}

function isUnderwriterInPersonalAndCommercialDepartment(roles) {
  return (
    roles.includes(iXRoles.UNDERWRITER_PERSONAL) &&
    roles.includes(iXRoles.UNDERWRITER_COMMERCIAL)
  );
}

function getUnderwriterStaffDepartment(roles) {
  let underwriterDepartment = UserJobDepartment.UNKNOWN;
  if (isUnderwriterStaff(roles)) {
    if (isUnderwriterInPersonalAndCommercialDepartment(roles)) {
      underwriterDepartment = UserJobDepartment.PERSONAL_AND_COMMERCIAL;
    } else if (roles.includes(iXRoles.UNDERWRITER_PERSONAL)) {
      underwriterDepartment = UserJobDepartment.PERSONAL;
    } else if (roles.includes(iXRoles.UNDERWRITER_COMMERCIAL)) {
      underwriterDepartment = UserJobDepartment.COMMERCIAL;
    } else {
      underwriterDepartment = UserJobDepartment.OTHER;
    }
  }
  return underwriterDepartment;
}

function getUserJob(roles) {
  let userJob = UserJob.UNKNOWN;
  if (!roles || roles.length === 0) {
    return userJob;
  }

  userJob = UserJob.OTHER;
  if (isCSRStaff(roles)) {
    userJob = UserJob.CSR;
  }

  if (isCSRSupervisor(roles)) {
    userJob = UserJob.CSR_SUPERVISOR;
  }

  if (isUnderwriterStaff(roles)) {
    userJob = UserJob.UNDERWRITER;
  }

  if (isUnderwriterSupervisor(roles)) {
    userJob = UserJob.UNDERWRITER_SUPERVISOR;
  }

  if (isUnderwriterSupportStaff(roles)) {
    userJob = UserJob.UNDERWRITING_SUPPORT;
  }

  if (isUnderwriterSupportSupervisor(roles)) {
    userJob = UserJob.UNDERWRITING_SUPPORT_SUPERVISOR;
  }
  return userJob;
}

function getUserDepartment(roles) {
  let userDepartment = UserJobDepartment.UNKNOWN;
  if (isUserInUnspecifiedDepartments(roles)) {
    userDepartment = UserJobDepartment.OTHER;
    return userDepartment;
  }

  if (isCSRStaff(roles)) {
    userDepartment = getCSRStaffDepartment(roles);
  }

  if (isUnderwriterStaff(roles)) {
    userDepartment = getUnderwriterStaffDepartment(roles);
  }
  return userDepartment;
}

/**
 * Identify the job and role of an user based on their ixRoles
 * @param {string[]} roles - An array of user roles from ixRoles (should not be empty).
 * @returns  {{ job: string, department: string }} - The calculated job and department of a user
 */
export default function getUserJobDetails(roles) {
  // converting all roles to lower case
  const userRoles = roles.map(role => role.toLowerCase());
  const job = getUserJob(userRoles);
  const department = getUserDepartment(userRoles);
  return { job, department };
}

/* eslint-disable no-console */
import moment from "moment";
import {
  PAYMENT_CANCEL,
  PAYMENT_COMPLETE,
  PAYMENT_ERROR,
  PAYMENT_READY,
  PAYMENT_START,
  SCHEDULED_PAYMENT_CREATED,
  SCHEDULED_PAYMENT_CANCELED
} from "@icg360/payment-toolkit-v2";
import { Alert } from "@icg360/ui-toolkit";
import { Button } from "@icg360/design-system";
import {
  QANDP_ONE_INC_MODAL_V2,
  QANDP_ONE_INC_SCHEDULED_PAYMENTS,
  QANDP_MAKE_A_PAYMENT_BUTTON,
  QANDP_INVOICE_AMOUNT_PAYMENT_OPTION,
  ViewWrapper,
  track
} from "@package/ipcmgr-toolkit";
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { PAYMENT_APPLICATION_CLIENT } from "qandp/constants";
import { QPContext } from "qandp/context";
import "./PaymentButton.css";

export const BTN_READY = "ready";
export const BTN_DISABLED = "disabled";
export const BTN_BUSY = "busy";

const PaymentButton = ({ className, appearance }) => {
  const {
    flags,
    paymentController,
    policy: {
      accounting,
      currentTerm,
      identifiers,
      primaryInsured,
      policyData,
      policyState
    }
  } = useContext(QPContext);

  const {
    invoiceAmountCurrent,
    invoiceDueDateCurrent,
    minimumPaymentDue,
    outstandingBalance
  } = accounting || {};
  const { agencyLocationCode } = currentTerm || {};
  const { insightPolicyId, policyId, quoteNumber } = identifiers || {};
  const { TotalPremium } = policyData || {};
  const {
    emailAddress,
    firstName,
    lastName,
    phoneNumber,
    address: primaryInsuredAddress
  } = primaryInsured || {};
  const { street1, city, state, zip } = primaryInsuredAddress || {};

  const [btnState, setBtnState] = useState(
    !paymentController?.isReady() ? BTN_BUSY : BTN_READY
  );
  const [showAlert, setShowAlert] = useState(false);
  const [errors, setErrors] = useState(null);

  const useV2 = !!flags[QANDP_ONE_INC_MODAL_V2];
  const useInvoiceAmountPaymentOption = !!flags[
    QANDP_INVOICE_AMOUNT_PAYMENT_OPTION
  ];

  const accountBalance =
    policyState === "CANCELLEDPOLICY" ? TotalPremium : outstandingBalance;

  const classes = classNames("", className, {
    hide: accountBalance <= 0
  });

  const mapAccountBalance = () => {
    if (useV2 && useInvoiceAmountPaymentOption) {
      return parseFloat(minimumPaymentDue);
    }
    return parseFloat(accountBalance);
  };
  const mapMinAmountDue = () => {
    if (useV2 && useInvoiceAmountPaymentOption) {
      return parseFloat(invoiceAmountCurrent);
    }
    return parseFloat(minimumPaymentDue);
  };

  const payment = {
    accountBalance: mapAccountBalance(),
    agencyLocationCode,
    billingCity: city,
    billingState: state,
    billingStreet: street1,
    billingZip: (zip || "").substring(0, 5),
    email: emailAddress,
    firstName,
    insightPolicyId,
    lastName,
    minAmountDue: mapMinAmountDue(),
    minCustomPaymentAmount: 1.0,
    paymentApplicationClient: PAYMENT_APPLICATION_CLIENT,
    phone: phoneNumber,
    policyId,
    quoteId: quoteNumber
  };

  const invoiceFinalPayDate = moment(invoiceDueDateCurrent).add(3, "days");
  const allowScheduledPayments =
    !!flags[QANDP_ONE_INC_SCHEDULED_PAYMENTS] &&
    invoiceFinalPayDate.isAfter(moment());

  if (useV2 && allowScheduledPayments) {
    payment.isScheduledPayEnabled = true;
    payment.finalPayDate = invoiceFinalPayDate.format("YYYY-MM-DD");
  }

  useEffect(() => {
    const disable = () => setBtnState(BTN_DISABLED);
    const ready = () => setBtnState(BTN_READY);
    const error = err => {
      const paymentError = err.toString();
      const noSessionId = paymentError.includes(
        "Could not get One, Inc. session ID"
      );
      const foreignPayment = paymentError.includes(
        "The billing zip does not match patterns of USA/Canada zip code"
      );

      if (noSessionId) {
        return paymentController.makePayment(payment);
      }

      if (foreignPayment) {
        setShowAlert(true);
        setErrors(
          `Digital payments are not available outside the U.S. at this time.`
        );
      }
      setBtnState(BTN_READY);
      console.error(paymentError);
      return false;
    };

    paymentController.addEventListener(PAYMENT_START, disable);
    paymentController.addEventListener(PAYMENT_READY, ready);
    paymentController.addEventListener(PAYMENT_CANCEL, ready);
    paymentController.addEventListener(PAYMENT_ERROR, error);
    paymentController.addEventListener(PAYMENT_COMPLETE, ready);
    paymentController.addEventListener(SCHEDULED_PAYMENT_CREATED, ready);
    paymentController.addEventListener(SCHEDULED_PAYMENT_CANCELED, ready);
    return () => {
      paymentController.removeEventListener(PAYMENT_START, disable);
      paymentController.removeEventListener(PAYMENT_READY, ready);
      paymentController.removeEventListener(PAYMENT_CANCEL, ready);
      paymentController.removeEventListener(PAYMENT_ERROR, error);
      paymentController.removeEventListener(PAYMENT_COMPLETE, ready);
      paymentController.removeEventListener(SCHEDULED_PAYMENT_CREATED, ready);
      paymentController.removeEventListener(SCHEDULED_PAYMENT_CANCELED, ready);
    };
  }, []);

  return (
    <ViewWrapper flag={flags[QANDP_MAKE_A_PAYMENT_BUTTON]}>
      {showAlert && (
        <Alert
          bsStyle="danger"
          isGlobal
          autoDismiss
          onDismiss={() => {
            setShowAlert(false);
            setErrors(null);
          }}
        >
          {errors}
        </Alert>
      )}
      <Button
        size="sm"
        appearance={appearance}
        className={classes}
        loading={btnState === BTN_BUSY}
        disabled={[BTN_BUSY, BTN_DISABLED].includes(btnState)}
        onClick={() => {
          track("Clicked Make a Payment");
          setBtnState(BTN_BUSY);
          paymentController.makePayment(payment);
        }}
      >
        Make a Payment
      </Button>
    </ViewWrapper>
  );
};

PaymentButton.propTypes = {
  className: PropTypes.string,
  appearance: PropTypes.string
};

PaymentButton.defaultProps = {
  className: "",
  appearance: "primary"
};

export default PaymentButton;

import { Alert } from "@icg360/ui-toolkit";
import "@icg360/ui-toolkit/react-day-picker/lib/style.css";
import loadGraphClient from "@package/ipcmgr-graphql-client";
import "@package/ipcmgr-toolkit/css/styles.css";
import "@package/ipcmgr-toolkit/css/modals.css";
import "@package/ipcmgr-toolkit/css/utility.css";
import { DesignSystemProvider } from "@icg360/design-system";
import React from "react";
import { ApolloProvider } from "react-apollo";
import ReactDOM from "react-dom";
import { TrackJS } from "trackjs";
import * as FullStory from "@fullstory/browser";
import * as Sentry from "@sentry/react";
import { createBrowserHistory } from "history";
import App from "./components/App";
import { loadRuntimeConfig } from "./config";
import queryConfig from "./graphql/config.graphql";
import { checkSession } from "./utils/user";
import packageJSON from "../package.json";
import installQualtrics from "./utils/qualtrics";
import initWebComponents from "./utils/webComponents";

const installTrackJS = ({ environmentId }) =>
  TrackJS.install({
    token: "76bc2e107fcd4007bdf4b17654b7d041",
    application: `ipc_manager`,
    version: packageJSON.version,
    onError: payload => {
      // add environment ID to payload
      payload.metadata.push({
        key: "Environment Id",
        value: environmentId
      });

      // add Fullstory session URL to payload
      /* eslint-disable no-underscore-dangle */
      const isFullstoryInitialized = !!window._fs_initialized;
      const isFullstoryInDevMode = !!window._fs_dev_mode;
      const fullstorySessionURL =
        isFullstoryInitialized &&
        !isFullstoryInDevMode &&
        FullStory.getCurrentSessionURL(true);

      if (fullstorySessionURL) {
        payload.metadata.push({
          key: "fullstorySessionURL",
          value: fullstorySessionURL
        });
      }

      return true;
    }
  });

const initFullstory = (config, identifiedUser) => {
  const isProduction = config?.environmentId === "prod";

  FullStory.init({
    orgId: "XMPRW",
    devMode: !isProduction
  });

  /**
   * We are noticing certain users were not appearing on FullStory.
   * We suspect this could be because their email identity was not recognized by FullStory.
   */
  if (identifiedUser) {
    const userData = identifiedUser.userdata;
    FullStory.identify(userData.email, {
      email: userData.email,
      displayName: userData.email
    });
  }
};

const initializeSentry = config => {
  const history = createBrowserHistory();
  Sentry.init({
    /**
     * Sentry tracking will only be enabled in Production.
     * Hardcode this to `true` if you want Sentry to run in every environment
     */
    enabled: config.environmentId === "prod",
    environment: config.environmentId,
    /**
     * Dynamically updates the Sentry DSN's Domain based on the current environment.
     *
     * When using the relay endpoint (in NON local environments), the domain must change depending on the environment.
     * This replaces a placeholder (`__DOMAIN__`) in the DSN configuration with the current browser host.
     */
    dsn: config.sentryDsn.replaceAll("__DOMAIN__", window.location.host),
    integrations: [Sentry.reactRouterV5BrowserTracingIntegration({ history })],
    // when set to 1.0, 100% of errors are sent to Sentry
    tracesSampleRate: 1.0,
    release: "ipc-manager-v1.0"
  });
};

const rootNode = document.getElementById("app");

const flattenConfig = items =>
  items.reduce(
    (acc, { id, value, values }) => ({
      ...acc,
      [id]: value || values
    }),
    {}
  );

const mergeConfigs = (config, graphqlClient) =>
  graphqlClient
    .query({ query: queryConfig })
    .then(({ data: { ipc, ipcm } }) => ({
      ...config,
      ipc: flattenConfig(ipc),
      ipcm: flattenConfig(ipcm)
    }))
    .catch(e => {
      // eslint-disable-next-line no-console
      console.warn(e);
      return { ...config, ipc: {}, ipcm: {} };
    });

// We find out information about the environment and instance at runtime.
// Based on that information, we load instance-specific information before
// mounting the application.
loadRuntimeConfig()
  .then(config =>
    Promise.all([
      config,
      loadGraphClient(Object.values(config.graphql).filter(i => i), config)
    ])
  )
  .then(([config, graphqlClient]) =>
    Promise.all([
      mergeConfigs(config, graphqlClient),
      graphqlClient,
      checkSession(config.services.url)
    ])
  )
  .then(([config, graphqlClient, identifiedUser]) => {
    if (config.environmentId === "prod") {
      installTrackJS(config);
      initializeSentry(config);
    }
    initFullstory(config, identifiedUser);
    installQualtrics(config);
    initWebComponents(config);

    ReactDOM.render(
      <ApolloProvider client={graphqlClient}>
        <DesignSystemProvider>
          <App config={config} identifiedUser={identifiedUser} />
        </DesignSystemProvider>
      </ApolloProvider>,
      rootNode
    );
  })
  .catch(err => {
    // eslint-disable-next-line no-console
    console.error(err);
    ReactDOM.render(
      <Alert isGlobal bsStyle="warning">
        The application failed to load.
        {process.env.NODE_ENV === "development"
          ? " Try connecting to the VPN or look for errors in the console."
          : " Try refreshing the page, or contact support for help."}
      </Alert>,
      rootNode
    );
  });
